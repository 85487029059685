import { uniqBy } from '@/services/sanitize.service';
import { handleDirectories } from '@/features/planning/planning/tree';

function getBundleNode(bundle) {
    return {
        id: bundle.id,
        name: bundle.label,
        type: 'group',
        nodeType: 'bundle',
        children: [],
        class: 'text-blue-600',
        collapsed: false,
    };
}
function getFolderNode(bundle, folder) {
    return {
        id: bundle.id + '-' + folder.id,
        name: folder.name,
        type: 'group',
        nodeType: 'folder',
        children: [],
        class: 'font-bold',
        collapsed: false,
        folderId: folder.id,
        bundleId: bundle.id,
    };
}

function getServiceNode(bundle, folder, service) {
    return {
        id: bundle.id + '-' + folder.id + '-' + service.id,
        name: service.name,
        type: 'group',
        nodeType: 'service',
        children: [],
        collapsed: false,
        summarize: true,
        tasks: service.tasks,
        bundle,
        predecessors: service.predecessors,
        serviceId: service.id,
        bundleId: service.bundleId,
        folderId: folder.id,
        parentDirectoryId: service.parentDirectoryId,
    };
}
export function buildTree(bundles, folders, directories, tasks) {
    const result = [];
    for (const bundle of bundles) {
        const bundleNode = getBundleNode(bundle);
        if (tasks.find((task) => task.service.bundleId === bundle.id)) {
            result.push(bundleNode);
            for (const folder of folders) {
                const folderNode = getFolderNode(bundle, folder);
                if (tasks.find((task) => task.service.bundleId === bundle.id && task.location.parentId === folder.id)) {
                    bundleNode.children.push(folderNode);
                    const services = getServices(bundle, folder, tasks);
                    if (services.length) {
                        const serviceNodes = [];
                        for (const service of services) {
                            const serviceNode = getServiceNode(bundle, folder, service);
                            serviceNodes.push(serviceNode);
                            const locationAsTasks = getTasks(bundle, folder, service, tasks);
                            if (locationAsTasks.length) {
                                for (const task of locationAsTasks) {
                                    serviceNode.children.push({
                                        ...task,
                                        name: task.location.name,
                                        nodeType: 'task',
                                        serviceId: service.id,
                                        bundleId: service.bundleId,
                                        folderId: folder.id,
                                    });
                                }
                            }
                        }
                        handleDirectories(directories, bundle.id + '_' + folder.id, folderNode.children, serviceNodes);
                    }
                }
            }
        }
    }
    return result;
}
function getServices(bundle, folder, tasks) {
    return uniqBy(
        tasks
            .filter((task) => task.location.parentId === folder.id && task.service.bundleId === bundle.id)
            .reduce((acc, task) => [...acc, task.service], []),
        'id',
    );
}
function getTasks(bundle, folder, service, tasks) {
    return tasks.filter(
        (task) =>
            task.location.parentId === folder.id &&
            task.service.bundleId === bundle.id &&
            task.serviceId === service.id,
    );
}

export default {
    buildTree,
};
