import { differenceInWorkingDays, getDuration } from '@/services/duration.service';
import { sortBy } from '@/services/sanitize.service';

function computeDates(tasks, agenda) {
    const groups = addDescendants(tasks);
    for (const group of groups) {
        fillGroupDates(group);
        fillGroupDuration(group, agenda);
        fillGroupProgress(group, agenda);
        fillGroupReferenceDates(group, agenda);
        fillGroupReferenceProgress(group);
        fillGroupLate(group);
        sortChildren(group);
    }
}

function sortChildren(task) {
    task.children = sortBy(task.children, (item) =>
        item && (item.realStartDate || item.startDate)
            ? (item.realStartDate || item.startDate).toISOString() + '_' + item.name
            : null,
    );
}
function fillGroupDates(group) {
    group.startDate = reduceToMin(group, (task) => task.startDate);
    group.endDate = reduceToMax(group, (task) => task.endDate);
    group.expectedEndDate = reduceToMax(group, (task) => task.expectedEndDate);
    group.expectedStartDate = reduceToMin(group, (task) => task.expectedStartDate);
    group.estimatedEndDate = reduceToMax(group, (task) => task.estimatedEndDate);
    group.realStartDate = reduceToMin(group, (task) => task.realStartDate || task.startDate);
    group.realEndDate = reduceToMax(group, (task) => task.estimatedEndDate);
}
function reduceToMax(group, valueFn) {
    return group.descendants.map(valueFn).reduce(maxDate, null);
}
function reduceToMin(group, valueFn) {
    return group.descendants.map(valueFn).reduce(minDate, null);
}
function maxDate(acc, date) {
    return acc ? (date && date > acc ? date : acc) : date;
}
function minDate(acc, date) {
    return acc ? (date && date < acc ? date : acc) : date;
}
function updateGroupReferenceDates(tasks, agenda) {
    for (const task of tasks) {
        if (task.children) {
            updateGroupReferenceDates(task.children, agenda);
            fillGroupReferenceDates(task, agenda);
            fillGroupReferenceProgress(task);
            fillGroupReferenceLate(task, agenda);
        }
    }
}
function fillGroupReferenceDates(group, agenda) {
    group.referenceStartDate = reduceToMin(group, (child) => child.referenceStartDate);
    group.referenceEndDate = reduceToMax(group, (child) => child.referenceEndDate);
    group.referenceDuration = getDuration(group.referenceEndDate, group.referenceStartDate, agenda, null);
}
function fillGroupReferenceLate(group, agenda) {
    group.referenceLate = getDuration(group.endDate, group.referenceEndDate, agenda, null) - 1;
}
function fillGroupLate(group) {
    const lastEstimatedEndDate = reduceToMax(group, (child) => child.estimatedEndDate);
    const lastExpectedEndDate = reduceToMax(group, (child) => child.expectedEndDate);
    if (lastEstimatedEndDate && lastExpectedEndDate) {
        group.late = getDuration(lastEstimatedEndDate, lastExpectedEndDate, [], null) - 1;
    }
}
function getGroupLate(group) {
    const lastEstimatedEndDate = reduceToMax(group, (child) => child.estimatedEndDate);
    const lastExpectedEndDate = reduceToMax(group, (child) => child.expectedEndDate);
    if (lastEstimatedEndDate && lastExpectedEndDate) {
        return getDuration(lastEstimatedEndDate, lastExpectedEndDate, [], null) - 1;
    }
    return null;
}
function fillGroupDuration(group, agenda) {
    if (group.endDate && group.startDate) {
        group.duration = getDuration(group.endDate, group.startDate, agenda, null);
    }
    if (group.realEndDate && group.realStartDate) {
        group.realDuration = getDuration(group.realEndDate, group.realStartDate, agenda, null);
    }
}
function fillGroupProgress(group) {
    const workload = group.descendants
        .map((child) => (child.realEndDate ? child.realDuration || child.estimatedRealDuration : child.duration))
        .reduce((acc, duration) => {
            return acc + duration;
        }, 0);
    group.workload = workload;
    if (workload > 0) {
        group.progress =
            (group.descendants
                .map((child) => {
                    const progress = child.progress / 100 || 0;
                    const duration = child.realEndDate
                        ? child.realDuration || child.estimatedRealDuration
                        : child.duration;
                    return progress * duration;
                })
                .reduce((acc, progress) => {
                    return acc + progress;
                }, 0) /
                workload) *
            100;
    } else {
        group.progress = 0;
    }
}
function fillGroupReferenceProgress(group) {
    const workload = group.descendants
        .map((child) => {
            return child.referenceWorkload || child.referenceDuration || 0;
        })
        .reduce((acc, duration) => {
            return acc + duration;
        }, 0);
    group.referenceWorkload = workload;
    group.referenceProgress =
        (group.descendants
            .map((child) => {
                const progress = child.referenceProgress / 100 || 0;
                const duration = child.referenceDuration;
                return progress * duration;
            })
            .reduce((acc, progress) => {
                return acc + progress;
            }, 0) /
            workload) *
        100;
}
function leavesMapByGroupId(tasks) {
    const childrenByLevel = [];
    const result = {};
    let previousTask = null;
    [...tasks].reverse().forEach((task) => {
        if (previousTask && previousTask.level > task.level) {
            result[task.id] = childrenByLevel[previousTask.level];
            childrenByLevel[previousTask.level] = [];
        }
        if (task.type !== 'group') {
            appendLeaf(childrenByLevel, task);
        }
        previousTask = task;
    });
    return result;
}
function appendLeaf(map, leaf, level) {
    const currentLevel = level >= 0 ? level : leaf.level;
    if (!map[currentLevel]) {
        map[currentLevel] = [];
    }

    map[currentLevel].push(leaf);

    if (currentLevel > 0) {
        appendLeaf(map, leaf, currentLevel - 1);
    }
}

function addDescendants(taskTreeRoots) {
    const groups = [];
    taskTreeRoots
        .map((child) => collectChildren(child, [], groups))
        .reduce((acc, children) => [...acc, ...children], []);
    return groups;
}
function collectChildren(node, children, groups) {
    if (node.children) {
        groups.push(node);
        const nodeChildren = node.children
            .map((child) => collectChildren(child, [], groups))
            .reduce((acc, children) => [...acc, ...children], []);
        node.descendants = nodeChildren;
        return [...children, ...nodeChildren];
    } else {
        return [node];
    }
}
function getAbsoluteGroupLate(descendants) {
    const referenceEndDate = reduceToMax({ descendants }, (task) => task.referenceEndDate);
    const estimatedEndDate = reduceToMax({ descendants }, (task) => task.estimatedEndDate);
    return differenceInWorkingDays(estimatedEndDate, referenceEndDate, []) - 1;
}
export default {
    computeDates,
    fillGroupDates,
    fillGroupProgress,
    leavesMapByGroupId,
    fillGroupLate,
    updateGroupReferenceDates,
    addDescendants,
    getAbsoluteGroupLate,
    getGroupLate,
};
