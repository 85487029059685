import { uniqBy } from '@/services/sanitize.service';
import { handleDirectories } from '@/features/planning/planning/tree';

function getFolderNode(folder) {
    return {
        id: folder.id,
        name: folder.name,
        type: 'group',
        children: [],
        class: 'font-bold',
        nodeType: 'folder',
        folderId: folder.id,
    };
}
function getBundleNode(folder, bundle) {
    return {
        id: folder.id + '-' + bundle.id,
        bundleId: bundle.id,
        folderId: folder.id,
        location: folder,
        name: bundle.label,
        type: 'group',
        children: [],
        class: 'text-blue-600',
        collapsed: false,
        nodeType: 'bundle',
    };
}
function getServiceNode(folder, bundle, service) {
    return {
        id: folder.id + '-' + bundle.id + '-' + service.id,
        name: service.name,
        type: 'group',
        children: [],
        collapsed: false,
        summarize: true,
        tasks: service.tasks,
        bundle,
        predecessors: service.predecessors,
        serviceId: service.id,
        bundleId: service.bundleId,
        folderId: folder.id,
        nodeType: 'service',
        parentDirectoryId: service.parentDirectoryId,
    };
}
export function buildTree(bundles, folders, directories, tasks) {
    const result = [];
    for (const folder of folders) {
        const folderNode = getFolderNode(folder);
        const bundles = getBundles(folder, tasks);
        if (bundles.length) {
            result.push(folderNode);
            for (const bundle of bundles) {
                const bundleNode = getBundleNode(folder, bundle);
                const services = getServices(folder, bundle, tasks);
                if (services.length) {
                    folderNode.children.push(bundleNode);
                    const serviceNodes = [];
                    for (const service of services) {
                        const serviceNode = getServiceNode(folder, bundle, service);
                        serviceNodes.push(serviceNode);
                        const locationAsTasks = getTasks(folder, bundle, service, tasks);
                        if (locationAsTasks.length) {
                            for (const task of locationAsTasks) {
                                serviceNode.children.push({ ...task, name: task.location.name });
                            }
                        }
                    }
                    handleDirectories(directories, bundle.id + '_' + folder.id, bundleNode.children, serviceNodes);
                }
            }
        }
    }
    return result;
}

function getBundles(folder, tasks) {
    return uniqBy(
        tasks
            .filter((task) => task.location.parentId === folder.id)
            .reduce((acc, task) => [...acc, task.service.bundle], []),
        'id',
    );
}
function getServices(folder, bundle, tasks) {
    return uniqBy(
        tasks
            .filter((task) => task.location.parentId === folder.id && task.service.bundleId === bundle.id)
            .reduce((acc, task) => [...acc, task.service], []),
        'id',
    );
}
function getTasks(folder, bundle, service, tasks) {
    return tasks.filter(
        (task) =>
            task.location.parentId === folder.id &&
            task.service.bundleId === bundle.id &&
            task.serviceId === service.id,
    );
}

export default {
    buildTree,
};
