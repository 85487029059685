import treeBFL from './treeBFL';
import treeFLB from './treeFLB';
import { getMapById } from '@/services/sanitize.service';
import treeFBL from '@/features/planning/planning/treeFBL';
import treeBESL from '@/features/planning/planning/treeBESL';
import { sortTasks } from '@/features/tasks/tasks.service';

export function linear(result, level, tree, sortKey) {
    for (const node of tree) {
        node.level = level;
        result.push(node);
        if (node.children) {
            linear(result, level + 1, sortTasks(node.children, sortKey), sortKey);
        }
    }
}

export function populateFLB(bundles, folders, directories, tasks) {
    return treeFLB.buildTree(bundles, folders, directories, tasks);
}

export function populateBFL(bundles, folders, directories, tasks) {
    return treeBFL.buildTree(bundles, folders, directories, tasks);
}
export function populateFBL(bundles, folders, directories, tasks) {
    return treeFBL.buildTree(bundles, folders, directories, tasks);
}
export function populateBESL(bundles, folders, directories, tasks) {
    return treeBESL.buildTree(bundles, folders, directories, tasks);
}

export function getDirectoryNode(directory, idPrefix) {
    return {
        id: idPrefix + '-' + directory.id,
        type: 'group',
        name: directory.name,
        index: directory.index,
        parentDirectoryId: directory.parentDirectoryId,
        children: [],
        class: 'font-bold',
    };
}

export function handleDirectories(directories, idPrefix, parentChildren, nodes) {
    const directoriesMap = getMapById(directories);
    const cache = {};
    for (const node of nodes) {
        if (!node.parentDirectoryId && !node.service?.parentDirectoryId) {
            if (!parentChildren.includes(node)) {
                parentChildren.push(node);
            }
        } else {
            const newNode = wrapWithDirectory(node, idPrefix, cache, directoriesMap);
            if (!parentChildren.includes(newNode)) {
                parentChildren.push(newNode);
            }
        }
    }
}
export function wrapWithDirectory(node, idPrefix, cache, directoriesMap) {
    const directory = directoriesMap[node.parentDirectoryId || (node.service && node.service.parentDirectoryId)];
    if (directory) {
        let parentNode = cache[idPrefix + directory.id];
        if (!parentNode) {
            parentNode = getDirectoryNode(directory, idPrefix);
            cache[idPrefix + directory.id] = parentNode;
        }
        if (!parentNode.children.includes(node)) {
            parentNode.children.push(node);
        }
        return wrapWithDirectory(parentNode, idPrefix, cache, directoriesMap);
    } else return node;
}

export default {
    populateBFL,
    populateFLB,
    populateFBL,
    populateBESL,
};
