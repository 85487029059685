import Vue from 'vue';
import VueRouter from 'vue-router';
import User from './features/users/User.vue';
import Planning from './features/planning/planning/Planning';
import PrintPlanning from './features/planning/planning/PrintPlanning';
import ProjectForm from './features/projects/ProjectForm';
import Project from './features/projects/Project';
import Projects from './features/projects/Projects';
import Company from './features/companies/Company';
import AutoControl from './features/autoControls/AutoControl';
import Bundles from './features/bundles/Bundles';
import Bundle from './features/bundles/Bundle';
import Preparation from './features/preparations/Preparation';
import Root from './views/Root';
import Login from './views/Login';
import CGU from './views/CGU';
import AppMainMenu from './components/AppMainMenu';
import Locations from './features/locations/Locations';
import Services from './features/services/Services';
import ServiceBundleInfo from './features/services/ServiceBundleInfo';
import ServiceDetails from './features/services/ServiceDetails';
import ServiceDirectoryInfo from './features/services/ServiceDirectoryInfo';
import Reference from './features/planning/references/Reference';
import References from './features/planning/references/References';
import Reception from './features/receptions/Reception';
import Agenda from './features/planning/agenda/Agenda';
import Supports from './features/supports/Supports';
import SupportForm from './features/supports/SupportForm';
import WeatherIssues from './features/planning/weatherIssues/WeatherIssues';
import WeatherIssue from './features/planning/weatherIssues/WeatherIssue';
import FollowTask from './features/follow/FollowTask';
import CertificateForm from './features/certificates/CertificateForm';
import FileViewer from './features/fileViewer/FileViewer';
import NewFollowObservation from '@/features/observations/NewFollowObservation';
import AutoControlObservationPage from '@/features/autoControls/AutoControlObservationPage';
import ReceptionObservationPage from '@/features/receptions/ReceptionObservationPage';
import FollowMeeting from '@/features/meetings/FollowMeeting';
import MeetingReports from '@/features/meetings/MeetingReports';
import MeetingReport from '@/features/meetings/MeetingReport';
import MeetingPrintReport from '@/features/meetings/MeetingPrintReport';

import MeetingReportCover from '@/features/meetings/MeetingReportCover';
import MeetingReportAttendance from '@/features/meetings/MeetingReportAttendance';
import MeetingReportAttachments from '@/features/meetings/MeetingReportAttachments';
import MeetingReportRecipients from '@/features/meetings/MeetingReportRecipients';
import MeetingReportVisualization from '@/features/meetings/MeetingReportVisualization';
import MeetingReportNextMeetings from '@/features/meetings/MeetingReportNextMeetings';
import MeetingReportPages from '@/features/meetings/MeetingReportPages';
import MeetingReportPlanning from '@/features/meetings/MeetingReportPlanning';
import Zone from '@/features/tours/Zone';
import FoldersTour from '@/features/tours/FoldersTour';
import Zones from '@/features/tours/Zones';
import LocationsTour from '@/features/tours/LocationsTour';
import PhotoTour from '@/features/tours/PhotoTour';
import ObservationsReports from '@/features/observationsReports/ObservationsReports';
import ObservationsReport from '@/features/observationsReports/ObservationsReport';
import ZoneObservation from '@/features/tours/ZoneObservation';
import ObservationsPrintReportCoverPage from '@/features/observationsPrintReports/ObservationsPrintReportCoverPage';
import ObservationsPrintReportMapPage from '@/features/observationsPrintReports/ObservationsPrintReportMapPage';
import ObservationsPrintReportTablePage from '@/features/observationsPrintReports/ObservationsPrintReportTablePage';
import ObservationsPrintReportPhotosPage from '@/features/observationsPrintReports/ObservationsPrintReportPhotosPage';
import SupportAttachmentViewer from '@/features/supports/SupportAttachmentViewer';
import MeetingReportViewer from '@/features/meetings/MeetingReportViewer';
import PreparationListPrint from '@/features/preparations/PreparationListPrint';
import PreparationsReports from '@/features/preparationsReports/PreparationsReports';
import PreparationsReport from '@/features/preparationsReports/PreparationsReport';
import PreparationVisaPrint from '@/features/preparations/preparationVisaPrint/PreparationVisaPrint';
import PreparationVisa from '@/features/preparations/PreparationVisa';
import MeetingReportBundlePages from '@/features/meetings/MeetingReportBundlePages';
import { track } from '@/features/tracker/tracker.service';
import NewUser from '@/features/users/NewUser';
import ConfirmedUser from '@/features/users/ConfirmedUser';
import FollowByBundle from '@/features/follow/FollowByBundle';
import Preparations from '@/features/preparations/Preparations';
import NewProjectShort from '@/features/projects/NewProjectShort.vue';
import ProjectDashboard from '@/features/dashboards/ProjectDashboard';
import MyDashboard from '@/features/dashboards/MyDashboard';
import PreparationAttachmentViewer from '@/features/preparations/PreparationAttachmentViewer';
import GroupDashboard from '@/features/dashboards/GroupDashboard';
import FollowObservations from '@/features/observations/FollowObservations';
import FollowTasks from '@/features/follow/FollowTasks';
import Certificates from '@/features/certificates/Certificates';
import Meetings from '@/features/meetings/Meetings';
import Receptions from '@/features/receptions/Receptions';
import AutoControls from '@/features/autoControls/AutoControls';
import PlanningExports from '@/features/planningExports/PlanningExports';
import Upgrade from '@/views/Upgrade';
import ResetPassword from '@/features/users/ResetPassword';
import MailTemplates from '@/features/mailTemplates/MailTemplates.vue';
import MailTemplate from '@/features/mailTemplates/MailTemplate.vue';
import DeliveryContacts from '@/features/deliveryContacts/DeliveryContacts.vue';
import DeliveryContact from '@/features/deliveryContacts/DeliveryContact.vue';
import Requests from '@/features/requests/Requests.vue';
import Request from '@/features/requests/Request.vue';
import ReadOnlyMeetingReports from '@/features/meetings/ReadOnlyMeetingReports.vue';
import Help from '@/components/Help.vue';
import Syntheses from '@/features/syntheses/Syntheses.vue';
import Synthesis from '@/features/syntheses/Synthesis.vue';
import Contracts from '@/features/contracts/Contracts.vue';
import Contract from '@/features/contracts/Contract.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: Root,
        meta: {
            menu: 'root',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: Projects,
                meta: {
                    title: 'home.list',
                    menu: 'projects',
                    hideMenu: true,
                },
            },
            {
                path: 'help',
                name: 'help',
                component: Help,
                meta: {
                    title: 'help.title',
                    menu: 'help',
                    hideMenu: true,
                },
            },
            {
                path: 'blank',
                name: 'blank',
                component: Vue.component('blank', { template: '<div></div>' }),
            },
            {
                path: '/project/:projectId',
                components: {
                    default: Project,
                    aside: AppMainMenu,
                },
                meta: {
                    title: ':projectName',
                    menu: 'project',
                },
                children: [
                    {
                        path: 'printPlanning',
                        name: 'printPlanning',
                        component: PrintPlanning,
                        meta: {
                            hideMenu: true,
                        },
                    },
                    {
                        path: 'printPreparationsReport/:preparationsReportId',
                        name: 'printPreparations',
                        component: PreparationListPrint,
                        meta: {
                            hideMenu: true,
                        },
                    },
                    {
                        path: 'printReport/:meetingId',
                        name: 'printReport',
                        component: MeetingPrintReport,
                        meta: {
                            hideMenu: true,
                        },
                    },
                    {
                        path: 'printPreparationVisa/:preparationId/:preparationVisaId',
                        name: 'printPreparationVisa',
                        component: PreparationVisaPrint,
                        meta: {
                            hideMenu: true,
                        },
                    },
                    {
                        path: 'printObservationsReport/:observationsReportId',
                        component: Vue.component('observationsReportWrapper', { template: '<router-view/>' }),
                        children: [
                            {
                                path: 'cover',
                                name: 'observationsPrintReportCoverPage',
                                component: ObservationsPrintReportCoverPage,
                                meta: {
                                    hideMenu: true,
                                },
                            },
                            {
                                path: ':zoneId/map',
                                name: 'observationsPrintReportMapPage',
                                component: ObservationsPrintReportMapPage,
                                meta: {
                                    hideMenu: true,
                                },
                            },
                            {
                                path: ':zoneId/table',
                                name: 'observationsPrintReportTablePage',
                                component: ObservationsPrintReportTablePage,
                                meta: {
                                    hideMenu: true,
                                },
                            },
                            {
                                path: ':zoneId/photos',
                                name: 'observationsPrintReportPhotosPage',
                                component: ObservationsPrintReportPhotosPage,
                                meta: {
                                    hideMenu: true,
                                },
                            },
                        ],
                    },
                    {
                        path: 'fileViewer',
                        name: 'fileViewer',
                        component: FileViewer,
                        meta: {},
                    },
                    {
                        path: 'companies/:companyId',
                        name: 'company',
                        component: Company,
                        meta: {
                            title: ':companyName',
                            menu: 'project.companies.company',
                            backToMenu: true,
                        },
                    },
                    {
                        path: 'planning',
                        component: Vue.component('planningWrapper', { template: '<router-view/>' }),
                        meta: {
                            menu: 'project.planning',
                            title: 'project.planning.title',
                        },
                        children: [
                            {
                                path: '',
                                name: 'planning',
                                component: Planning,
                                meta: {
                                    title: 'project.planning.planningTitle',
                                    menu: 'project.planning.planning',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: 'graph',
                                name: 'PlanningGraph',
                                component: () => import('@/features/planning/planning/PlanningGraph'),
                                meta: {
                                    title: 'project.planning.planningTitle',
                                    menu: 'project.planning.planning',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: 'references',
                                component: Vue.component('references', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.reference.title',
                                    menu: 'project.planning.references',
                                    parentName: 'planning',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'references',
                                        component: References,
                                        meta: {
                                            menu: 'project.planning.references',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':referenceId',
                                        name: 'reference',
                                        component: Reference,
                                        meta: {
                                            title: ':referenceName',
                                            menu: 'project.planning.references',
                                            parentName: 'references',
                                        },
                                    },
                                ],
                            },

                            {
                                path: 'weatherIssues',
                                component: Vue.component('weatherIssuesWrapper', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.weatherIssues.title',
                                    menu: 'project.planning.weatherIssues',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'weatherIssues',
                                        component: WeatherIssues,
                                        meta: {
                                            menu: 'project.planning.weatherIssues',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: '',
                                        component: Vue.component('aWeatherIssue', { template: '<router-view/>' }),
                                        meta: {
                                            menu: 'project.planning.weatherIssue',
                                        },
                                        children: [
                                            {
                                                path: ':weatherIssueId',
                                                name: 'weatherIssue',
                                                component: WeatherIssue,
                                                meta: {
                                                    title: ':weatherIssueName',
                                                    menu: 'project.planning.weatherIssue',
                                                    parentName: 'weatherIssues',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'agenda',
                                component: Vue.component('AgendaWrapper', { template: '<router-view/>' }),
                                meta: {
                                    menu: 'project.planning.agenda',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'agenda',
                                        component: Agenda,
                                        meta: {
                                            title: 'project.follow.agenda.title',
                                            menu: 'project.planning.agenda',
                                            backToMenu: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'follow',
                        component: Vue.component('aFollow', { template: '<router-view/>' }),
                        meta: {
                            title: 'project.follow.title',
                            menu: 'project.follow',
                        },
                        children: [
                            { path: '', redirect: 'byBundle' },
                            {
                                path: 'tour',
                                component: Vue.component('aTour', { template: '<router-view/>' }),
                                meta: {
                                    title: 'tour.title',
                                    menu: 'project.follow.tour',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'foldersTour',
                                        component: FoldersTour,
                                        meta: {
                                            menu: 'project.follow.tour',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':folderId',
                                        component: Vue.component('aPhaseTour', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':folderName',
                                            menu: 'project.follow.tour',
                                            backToMenu: true,
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'locationsTour',
                                                component: LocationsTour,
                                                meta: {
                                                    menu: 'project.follow.tour',
                                                    parentName: 'foldersTour',
                                                },
                                            },
                                            {
                                                path: ':locationId',
                                                component: Vue.component('aZones', {
                                                    template: '<router-view/>',
                                                }),
                                                meta: {
                                                    title: ':locationName',
                                                    menu: 'project.follow.tour',
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'zonesTour',
                                                        component: Zones,
                                                        meta: {
                                                            menu: 'project.follow.tour',
                                                            parentName: 'locationsTour',
                                                        },
                                                    },
                                                    {
                                                        path: ':zoneId',
                                                        component: Vue.component('aListsTour', {
                                                            template: '<router-view/>',
                                                        }),
                                                        meta: {
                                                            title: ':zoneName',
                                                            menu: 'project.follow.tour',
                                                        },
                                                        children: [
                                                            {
                                                                path: '',
                                                                name: 'zoneTour',
                                                                component: Zone,
                                                                meta: {
                                                                    menu: 'project.follow.tour',
                                                                    parentName: 'zonesTour',
                                                                },
                                                            },
                                                            {
                                                                path: 'photo/:observationId/:attachmentId',
                                                                name: 'zonePhotoTour',
                                                                component: PhotoTour,
                                                                meta: {
                                                                    menu: 'project.follow.tour',
                                                                    parentName: 'zoneTour',
                                                                },
                                                            },
                                                            {
                                                                path: 'observation/:observationId',
                                                                component: Vue.component('aObservationTour', {
                                                                    template: '<router-view/>',
                                                                }),
                                                                meta: {
                                                                    title: ':observationName',
                                                                    menu: 'project.follow.tour',
                                                                },
                                                                children: [
                                                                    {
                                                                        path: '',
                                                                        name: 'observationTour',
                                                                        component: ZoneObservation,
                                                                        meta: {
                                                                            menu: 'project.follow.tour',
                                                                            parentName: 'zoneTour',
                                                                        },
                                                                    },
                                                                    {
                                                                        path: ':attachmentId',
                                                                        name: 'observationTourPhoto',
                                                                        component: PhotoTour,
                                                                        meta: {
                                                                            menu: 'project.follow.tour',
                                                                            parentName: 'observationTour',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'byBundle',
                                component: Vue.component('aByBundle', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.byBundle.title',
                                    menu: 'project.follow.byBundle',
                                },
                                children: [
                                    {
                                        path: ':bundleId?',
                                        component: Vue.component('aByBundle', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':bundleName',
                                            menu: 'project.follow.byBundle',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'followByBundle',
                                                component: FollowByBundle,
                                                meta: {
                                                    menu: 'project.follow.byBundle',
                                                    backToMenu: true,
                                                },
                                            },
                                            {
                                                path: 'observation/:observationId',
                                                name: 'followByBundleObservation',
                                                component: NewFollowObservation,
                                                meta: {
                                                    menu: 'project.follow.byBundle',
                                                    parentName: 'followByBundle',
                                                },
                                            },
                                            {
                                                path: 'reception/:receptionId',
                                                name: 'followByBundleReception',
                                                component: Reception,
                                                meta: {
                                                    menu: 'project.follow.byBundle',
                                                    parentName: 'followByBundle',
                                                },
                                            },
                                            {
                                                path: 'autoControl/:autoControlId',
                                                name: 'followByBundleAutoControl',
                                                component: AutoControl,
                                                meta: {
                                                    menu: 'project.follow.byBundle',
                                                    parentName: 'followByBundle',
                                                },
                                            },
                                            {
                                                path: 'preparation/:preparationId',
                                                name: 'followByBundlePreparation',
                                                component: Preparation,
                                                meta: {
                                                    menu: 'project.follow.byBundle',
                                                    parentName: 'followByBundle',
                                                },
                                            },
                                            {
                                                path: 'preparationVisa/:preparationId/:preparationVisaId',
                                                name: 'followByBundlePreparationVisa',
                                                component: PreparationVisa,
                                                meta: {
                                                    title: ':visaName',
                                                    menu: 'project.follow.byBundle',
                                                    parentName: 'followByBundle',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'syntheses',
                                component: Vue.component('aSyntheses', { template: '<router-view/>' }),
                                meta: {
                                    title: 'syntheses.menuLabel',
                                    menu: 'project.follow.syntheses',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followSyntheses',
                                        component: Syntheses,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':observationId',
                                        name: 'followSynthesis',
                                        component: Synthesis,
                                        meta: {
                                            title: '',
                                            menu: 'project.follow.syntheses',
                                            parentName: 'followSyntheses',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'certificates',
                                component: Vue.component('aFollow', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.certificateTitle',
                                    menu: 'project.follow.certificates',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followCertificates',
                                        component: Certificates,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':certificateId',
                                        name: 'followCertificate',
                                        component: CertificateForm,
                                        meta: {
                                            title: ':certificateName',
                                            menu: 'project.follow.certificates',
                                            parentName: 'followCertificates',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'autoControls',
                                component: Vue.component('aFollowAutoControls', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.autoControlTitle',
                                    menu: 'project.follow.autoControls',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followAutoControls',
                                        component: AutoControls,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':autoControlId',
                                        component: Vue.component('aFollowAutoControl', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':autoControlName',
                                            menu: 'project.follow.autoControls',
                                            parentName: 'followAutoControls',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'followAutoControl',
                                                component: AutoControl,
                                                meta: {
                                                    menu: 'project.follow.autoControls',
                                                    parentName: 'followAutoControls',
                                                },
                                            },
                                            {
                                                path: 'observations',
                                                component: Vue.component('aFollowAutoControlObservations', {
                                                    template: '<router-view/>',
                                                }),
                                                meta: {
                                                    menu: 'project.follow.autoControls',
                                                    parentName: 'followAutoControls',
                                                    title: 'observations.title',
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'followAutoControlObservations',
                                                        component: FollowObservations,
                                                        meta: {
                                                            menu: 'project.follow.autoControls',
                                                            parentName: 'followAutoControl',
                                                        },
                                                    },
                                                    {
                                                        path: ':observationId',
                                                        name: 'followAutoControlObservation',
                                                        component: AutoControlObservationPage,
                                                        meta: {
                                                            title: ':observationName',
                                                            menu: 'project.follow.autoControls',
                                                            parentName: 'followAutoControlObservations',
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'preparations',
                                component: Vue.component('aFollowPreparations', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.preparationTitle',
                                    menu: 'project.follow.preparations',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followPreparations',
                                        component: Preparations,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':preparationId',
                                        component: Vue.component('aFollowPreparation', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':preparationName',
                                            menu: 'project.follow.preparations',
                                            parentName: 'followPreparations',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'followPreparation',
                                                component: Preparation,
                                                meta: {
                                                    menu: 'project.follow.preparations',
                                                    parentName: 'followPreparations',
                                                },
                                            },
                                            {
                                                path: ':preparationVisaId',
                                                component: Vue.component('aPreparation', {
                                                    template: '<router-view/>',
                                                }),
                                                meta: {
                                                    title: ':visaName',
                                                    menu: 'project.follow.preparations',
                                                    parentName: 'conception',
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'preparationVisa',
                                                        component: PreparationVisa,
                                                        meta: {
                                                            menu: 'project.follow.preparations',
                                                            parentName: 'followPreparations',
                                                        },
                                                    },
                                                    {
                                                        path: ':documentId',
                                                        name: 'preparationDocument',
                                                        component: PreparationAttachmentViewer,
                                                        meta: {
                                                            menu: 'project.follow.preparations',
                                                            parentName: 'preparationVisa',
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'receptions',
                                component: Vue.component('aFollowReceptions', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.receptionTitle',
                                    menu: 'project.follow.receptions',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followReceptions',
                                        component: Receptions,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':receptionId',
                                        component: Vue.component('aFollowReception', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':receptionName',
                                            menu: 'project.follow.receptions',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'followReception',
                                                component: Reception,
                                                meta: {
                                                    menu: 'project.follow.receptions',
                                                    parentName: 'followReceptions',
                                                },
                                            },
                                            {
                                                path: 'observations',
                                                component: Vue.component('aFollowReceptionObservations', {
                                                    template: '<router-view/>',
                                                }),
                                                meta: {
                                                    menu: 'project.follow.receptions',
                                                    parentName: 'followReceptions',
                                                    title: 'observations.title',
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'followReceptionObservations',
                                                        component: FollowObservations,
                                                        meta: {
                                                            menu: 'project.follow.receptions',
                                                            parentName: 'followReception',
                                                        },
                                                    },
                                                    {
                                                        path: ':observationId',
                                                        name: 'followReceptionObservation',
                                                        component: ReceptionObservationPage,
                                                        meta: {
                                                            title: ':observationName',
                                                            menu: 'project.follow.receptions',
                                                            parentName: 'followReceptionObservations',
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'tasks',
                                component: Vue.component('aFollowTasks', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.taskTitle',
                                    menu: 'project.follow.tasks',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followTasks',
                                        component: FollowTasks,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: 'task/:taskId',
                                        name: 'followTask',
                                        component: FollowTask,
                                        meta: {
                                            title: ':taskName',
                                            menu: 'project.follow.tasks',
                                            parentName: 'followTasks',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'observations',
                                component: Vue.component('aFollowObservations', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.observationTitle',
                                    menu: 'project.follow.observations',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followObservations',
                                        component: FollowObservations,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':observationId',
                                        name: 'followObservation',
                                        component: NewFollowObservation,
                                        meta: {
                                            title: ':observationName',
                                            menu: 'project.follow.observations',
                                            parentName: 'followObservations',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'requests',
                                component: Vue.component('aFollow', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.requests.title',
                                    menu: 'project.follow.requests',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'requests',
                                        component: Requests,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: ':requestId',
                                        name: 'request',
                                        component: Request,
                                        meta: {
                                            title: ':requestName',
                                            menu: 'project.follow.requests',
                                            parentName: 'requests',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'meetings',
                                component: Vue.component('aFollowMeetings', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.follow.meetings.title',
                                    menu: 'project.follow.meetings',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'followMeetings',
                                        component: Meetings,
                                        meta: { backToMenu: true },
                                    },
                                    {
                                        path: 'meetings/:meetingId',
                                        component: Vue.component('aFollowMeeting', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':meetingName',
                                            menu: 'project.follow.meetings',
                                            parentName: 'followMeetings',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'followMeeting',
                                                component: FollowMeeting,
                                                meta: {
                                                    menu: 'project.follow.meetings',
                                                    parentName: 'followMeetings',
                                                },
                                            },
                                            {
                                                path: 'viewer',
                                                name: 'followMeetingReportViewerDeep',
                                                component: MeetingReportViewer,
                                                meta: {
                                                    menu: 'project.follow.meetings',
                                                    parentName: 'followMeeting',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'dashboard',
                        component: Vue.component('dashboard', { template: '<router-view/>' }),
                        meta: {
                            title: 'dashboard.title',
                            menu: 'project.dashboard',
                        },
                        children: [
                            {
                                path: '',
                                name: 'myDashboard',
                                component: MyDashboard,
                                meta: {
                                    title: 'dashboard.myDashboard.title',
                                    menu: 'project.myDashboard',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: 'project',
                                name: 'projectDashboard',
                                component: ProjectDashboard,
                                meta: {
                                    title: 'dashboard.projectDashboard.title',
                                    menu: 'project.projectDashboard',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: 'byBundleDashboard',
                                name: 'byBundleDashboard',
                                component: GroupDashboard,
                                meta: {
                                    title: 'dashboard.byBundleDashboardTitle',
                                    menu: 'project.byBundleDashboardTitle',
                                    backToMenu: true,
                                },
                            },
                        ],
                    },
                    {
                        path: 'conceptions',
                        component: Vue.component('conceptions', { template: '<router-view/>' }),
                        meta: {
                            title: 'conceptions.elementTitle',
                            menu: 'project.conception.conceptions',
                        },
                        children: [
                            {
                                path: '',
                                name: 'conceptions',
                                component: Preparations,
                                meta: {
                                    menu: 'project.conception.conceptions',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: ':preparationId',
                                component: Vue.component('aConceptions', { template: '<router-view/>' }),
                                meta: {
                                    title: ':preparationName',
                                    menu: 'project.conception.conceptions',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'conception',
                                        component: Preparation,
                                        meta: {
                                            menu: 'project.conception.conceptions',
                                            parentName: 'conceptions',
                                        },
                                    },
                                    {
                                        path: ':preparationVisaId',
                                        component: Vue.component('aConceptions', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':visaName',
                                            menu: 'project.conception.conceptions.conception',
                                            parentName: 'conception',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'conceptionVisa',
                                                component: PreparationVisa,
                                                meta: {
                                                    menu: 'project.conception.conceptions.conception',
                                                    parentName: 'conception',
                                                },
                                            },
                                            {
                                                path: ':documentId',
                                                name: 'conceptionDocument',
                                                component: PreparationAttachmentViewer,
                                                meta: {
                                                    menu: 'project.conception.conceptions',
                                                    parentName: 'conceptionVisa',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: 'information',
                        component: Vue.component('aInformation', { template: '<router-view/>' }),
                        meta: {
                            title: 'project.information.title',
                            menu: 'project.information',
                        },
                        children: [
                            {
                                path: '',
                                name: 'project',
                                component: ProjectForm,
                                meta: {
                                    title: 'project.information.generals.title',
                                    menu: 'project.information.generals',
                                    backToMenu: true,
                                },
                            },
                            {
                                path: 'folders',
                                component: Vue.component('folders', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.information.folders.title',
                                    menu: 'project.information.folders',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'folders',
                                        component: Locations,
                                        meta: {
                                            menu: 'project.information.folders.folders',
                                            backToMenu: true,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'bundles',
                                component: Vue.component('bundles', { template: '<router-view/>' }),
                                meta: {
                                    title: 'bundles.bundles',
                                    menu: 'project.information.bundles',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'bundles',
                                        component: Bundles,
                                        meta: {
                                            menu: 'project.information.bundles.bundles',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':bundleId',
                                        name: 'bundle',
                                        component: Bundle,
                                        meta: {
                                            title: ':bundleName',
                                            menu: 'project.information.bundles.bundle',
                                            parentName: 'bundles',
                                        },
                                    },
                                    {
                                        path: 'company/:companyId',
                                        name: 'bundlesCompany',
                                        component: Company,
                                        meta: {
                                            title: ':companyName',
                                            menu: 'project.information.bundles.bundle',
                                            parentName: 'bundles',
                                        },
                                    },
                                    {
                                        path: ':bundleId/company/:companyId',
                                        name: 'bundleCompany',
                                        component: Company,
                                        meta: {
                                            title: ':companyName',
                                            menu: 'project.information.bundles.bundle',
                                            parentName: 'bundle',
                                        },
                                    },
                                ],
                            },

                            {
                                path: 'services',
                                component: Vue.component('services', { template: '<router-view/>' }),
                                meta: {
                                    title: 'services.title',
                                    menu: 'project.information.services',
                                    meta: { backToMenu: true },
                                },
                                children: [
                                    {
                                        path: 'graph',
                                        name: 'ServicesGraph',
                                        component: () => import('@/features/services/ServicesGraph'),
                                        meta: {
                                            menu: 'project.information.services.services',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: '',
                                        name: 'services',
                                        component: Services,
                                        meta: {
                                            menu: 'project.information.services.services',
                                            backToMenu: true,
                                        },
                                        children: [
                                            {
                                                path: ':bundleId',
                                                name: 'serviceBundleInfo',
                                                component: ServiceBundleInfo,
                                                meta: {
                                                    menu: 'project.information.services.services',
                                                    backToMenu: false,
                                                    parentName: 'services',
                                                },
                                            },
                                            {
                                                path: ':bundleId/directory/:directoryId',
                                                name: 'serviceDirectoryInfo',
                                                component: ServiceDirectoryInfo,
                                                meta: {
                                                    menu: 'project.information.services.services',
                                                    backToMenu: false,
                                                    parentName: 'services',
                                                },
                                            },
                                            {
                                                path: ':bundleId/service/:serviceId',
                                                name: 'service',
                                                component: ServiceDetails,
                                                meta: {
                                                    menu: 'project.information.services.services',
                                                    backToMenu: false,
                                                    parentName: 'services',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'supports',
                                component: Vue.component('supports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'supports.title',
                                    menu: 'project.information.supports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'supports',
                                        component: Supports,
                                        meta: {
                                            menu: 'project.information.supports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':supportId',
                                        component: Vue.component('supports', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':supportName',
                                            menu: 'project.information.supports',
                                            parentName: 'supports',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                component: SupportForm,
                                                name: 'support',
                                                meta: {
                                                    title: '',
                                                    menu: 'project.information.supports',
                                                    parentName: 'supports',
                                                },
                                            },
                                            {
                                                path: 'viewer',
                                                component: SupportAttachmentViewer,
                                                name: 'supportAttachmentViewer',
                                                meta: {
                                                    title: '',
                                                    menu: 'project.information.supports',
                                                    parentName: 'support',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'deliveryContacts',
                                component: Vue.component('deliveryContacts', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.information.deliveryContacts.title',
                                    menu: 'project.information.deliveryContacts',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'deliveryContacts',
                                        component: DeliveryContacts,
                                        meta: {
                                            menu: 'project.information.deliveryContacts',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':deliveryContactId',
                                        component: Vue.component('deliveryContacts', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':deliveryContactName',
                                            menu: 'project.information.deliveryContacts',
                                            parentName: 'deliveryContacts',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                component: DeliveryContact,
                                                name: 'deliveryContact',
                                                meta: {
                                                    title: '',
                                                    menu: 'project.information.deliveryContacts',
                                                    parentName: 'deliveryContacts',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'editions',
                        component: Vue.component('aEdition', { template: '<router-view/>' }),
                        meta: {
                            title: 'project.editions.title',
                            menu: 'project.editions',
                        },
                        children: [
                            {
                                path: '',
                                component: Vue.component('aObservationReports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.editions.observationsReports.menuTitle',
                                    menu: 'project.editions.observationsReports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'observationsReports',
                                        component: ObservationsReports,
                                        meta: {
                                            menu: 'project.editions.observationsReports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: 'observationsReports/:observationsReportId',
                                        name: 'observationsReport',
                                        component: ObservationsReport,
                                        meta: {
                                            title: ':observationsReportName',
                                            menu: 'project.editions.observationsReports',
                                            backToMenu: true,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'preparationsReports',
                                component: Vue.component('aPreparationsReports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.editions.preparationsReports.title',
                                    menu: 'project.editions.preparationsReports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'preparationsReports',
                                        component: PreparationsReports,
                                        meta: {
                                            menu: 'project.editions.preparationsReports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':preparationsReportId',
                                        name: 'preparationsReport',
                                        component: PreparationsReport,
                                        meta: {
                                            title: ':preparationsReportName',
                                            menu: 'project.editions.preparationsReports',
                                            parentName: 'preparationsReports',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'conceptionsReports',
                                component: Vue.component('conceptionsReports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.editions.conceptionsReports.title',
                                    menu: 'project.editions.conceptionsReports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'conceptionsReports',
                                        component: PreparationsReports,
                                        meta: {
                                            menu: 'project.editions.conceptionsReports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: ':preparationsReportId',
                                        name: 'conceptionsReport',
                                        component: PreparationsReport,
                                        meta: {
                                            title: ':preparationsReportName',
                                            menu: 'project.editions.conceptionsReports',
                                            parentName: 'conceptionsReports',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'planningExports',
                                component: Vue.component('aPlanningExports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.editions.planningExports.title',
                                    menu: 'project.editions.planningExports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'planningExports',
                                        component: PlanningExports,
                                        meta: {
                                            menu: 'project.editions.planningExports',
                                            backToMenu: true,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'meetings',
                                component: Vue.component('aMeetingReports', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.editions.meetingReports.title',
                                    menu: 'project.editions.meetingReports',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'meetingReports',
                                        component: MeetingReports,
                                        meta: {
                                            title: 'project.editions.meetingReports.title',
                                            menu: 'project.editions.meetingReports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: 'reports',
                                        name: 'readOnlyMeetingReports',
                                        component: ReadOnlyMeetingReports,
                                        meta: {
                                            title: 'project.editions.meetingReports.title',
                                            menu: 'project.editions.meetingReports',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: 'meetings/:meetingId',
                                        component: Vue.component('aMeetingReports', { template: '<router-view/>' }),
                                        meta: {
                                            title: ':meetingName',
                                            menu: 'project.editions.meetingReports',
                                        },
                                        children: [
                                            {
                                                path: '',
                                                component: MeetingReport,
                                                meta: {
                                                    menu: 'project.editions.meetingReports',
                                                    parentName: 'meetingReports',
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        name: 'meetingReport',
                                                        component: MeetingReportPages,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'cover',
                                                        name: 'meetingReportCover',
                                                        component: MeetingReportCover,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'attendance',
                                                        name: 'meetingReportAttendance',
                                                        component: MeetingReportAttendance,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'attachments',
                                                        name: 'meetingReportAttachments',
                                                        component: MeetingReportAttachments,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'recipients',
                                                        name: 'meetingReportRecipients',
                                                        component: MeetingReportRecipients,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'bundles',
                                                        name: 'meetingReportBundlePages',
                                                        component: MeetingReportBundlePages,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'nextMeetings',
                                                        name: 'meetingReportNextMeetings',
                                                        component: MeetingReportNextMeetings,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'planning',
                                                        name: 'meetingReportPlanning',
                                                        component: MeetingReportPlanning,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                    {
                                                        path: 'meetingReportVisualization',
                                                        name: 'meetingReportVisualization',
                                                        component: MeetingReportVisualization,
                                                        meta: { parentName: 'meetingReports' },
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'viewer',
                                                name: 'meetingReportViewerDeep',
                                                component: MeetingReportViewer,
                                                meta: {
                                                    menu: 'project.editions.meetingReports',
                                                    parentName: 'meetingReport',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        path: 'viewer/:meetingId',
                                        name: 'meetingReportViewer',
                                        component: MeetingReportViewer,
                                        meta: {
                                            title: '',
                                            menu: 'project.editions.meetingReports',
                                            parentName: 'meetingReports',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'diffusion',
                        component: Vue.component('aDiffusion', { template: '<router-view/>' }),
                        meta: {
                            title: 'project.diffusion.title',
                            menu: 'project.diffusion',
                        },
                        children: [
                            {
                                path: '',
                                component: Vue.component('aMailTemplates', { template: '<router-view/>' }),
                                meta: {
                                    title: 'project.diffusion.mailTemplates.menuTitle',
                                    menu: 'project.diffusion.mailTemplates',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'mailTemplates',
                                        component: MailTemplates,
                                        meta: {
                                            menu: 'project.diffusion.mailTemplates',
                                            backToMenu: true,
                                        },
                                    },
                                    {
                                        path: 'mailTemplates/:mailTemplateId',
                                        name: 'mailTemplate',
                                        component: MailTemplate,
                                        meta: {
                                            title: ':mailTemplateName',
                                            menu: 'project.diffusion.mailTemplates',
                                            backToMenu: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/user',
                name: 'user',
                meta: {
                    title: 'user.title',
                    backToMenu: true,
                    hideMenu: true,
                },
                component: User,
            },
            {
                path: '/contracts',
                name: 'contracts',
                meta: {
                    title: 'contracts.title',
                    backToMenu: true,
                    hideMenu: true,
                },
                component: Contracts,
            },
            {
                path: '/contracts/:contractId',
                name: 'contract',
                meta: {
                    title: 'contracts.title',
                    backToMenu: true,
                    hideMenu: true,
                },
                component: Contract,
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/upgrading',
        name: 'upgrading',
        component: Upgrade,
    },
    {
        path: '/CGU',
        name: 'cgu',
        component: CGU,
    },
    {
        path: '/newProject',
        name: 'newProjectShort',
        component: NewProjectShort,
        meta: {
            title: 'newProjectShort.title',
            menu: 'newProjectShort',
        },
    },
    {
        path: '/newUser',
        name: 'newUser',
        component: NewUser,
        meta: {
            hideMenu: true,
        },
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            hideMenu: true,
        },
    },
    {
        path: '/confirmedUser',
        name: 'confirmedUser',
        component: ConfirmedUser,
        meta: {
            hideMenu: true,
        },
    },
];

const appRouter = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(from, to, position) {
        if (
            (from.name && from.name.startsWith('service') && to.name && to.name.startsWith('service')) ||
            (from.name && from.name === 'folders' && to.name && to.name === 'folders')
        ) {
            return position;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

appRouter.beforeEach(async (to, from, next) => {
    if (
        !['login', 'resetPassword', 'newUser', 'newProjectShort', 'confirmedUser'].includes(to.name) &&
        !localStorage.getItem('token')
    ) {
        console.log('go to login');
        next({ name: 'login', query: { redirectTo: window.location } });
    } else {
        next();
    }
});
appRouter.beforeResolve((to, from, next) => {
    next();
    return track({
        referer: to ? to.fullPath : from.fullPath,
    });
});
export default appRouter;
