<template>
    <fragment>
        <td class="border pr-1 text-right">
            <p>R</p>
        </td>
        <td class="border text-center" :style="task.realStartDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.realStartDate | humanizeDate }}</span>
        </td>
        <td class="border text-center" :style="task.realEndDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.realEndDate | humanizeDate }}</span>
        </td>
        <td class="border text-right" v-if="options.showProgressColumn">
            {{ task.progress ? Math.round(task.progress) + '%' : '' }}
        </td>
        <td class="border px-1 text-right" v-if="options.showDurationColumn">
            <span>
                {{ task.realDuration || task.estimatedRealDuration }}
            </span>
        </td>
        <td class="border" v-if="options.showQuantityColumn"></td>
        <td class="border text-right px-1" v-if="options.showLateColumn">
            <span
                v-if="task.isCritical || task.type === 'group'"
                :class="{
                    'text-green-600 font-bold': task.criticalLate < 0,
                    'text-red-600 font-bold': task.criticalLate > 0,
                }"
            >
                {{ task.criticalLate > 0 ? '+' + task.criticalLate : task.criticalLate }}
            </span>
            <span v-else>&nbsp;</span>
        </td>
    </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
    components: { Fragment },
    props: ['task', 'options'],
};
</script>
