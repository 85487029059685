import { handleDirectories } from './tree';

function getBundleNode(bundle) {
    return {
        id: bundle.id,
        name: bundle.label,
        type: 'group',
        nodeType: 'bundle',
        children: [],
        class: 'text-blue-600',
        collapsed: false,
    };
}
function getFolderNode(bundle, folder) {
    return {
        id: bundle.id + '-' + folder.id,
        name: folder.name,
        type: 'group',
        children: [],
        class: 'font-bold',
        collapsed: false,
        nodeType: 'folder',
        folderId: folder.id,
        bundleId: bundle.id,
    };
}
function getLocationNode(bundle, folder, location) {
    return {
        id: bundle.id + '-' + folder.id + '-' + location.id,
        name: location.name,
        type: 'group',
        children: [],
        class: 'font-bold',
        collapsed: false,
        nodeType: 'location',
        folderId: folder.id,
        locationId: location.id,
        bundleId: bundle.id,
    };
}
export function buildTree(bundles, folders, directories, tasks) {
    const result = [];
    let remainingTasks = [...tasks];
    for (const bundle of bundles) {
        const bundleNode = getBundleNode(bundle);
        if (remainingTasks.find((task) => task.service.bundleId === bundle.id)) {
            result.push(bundleNode);
            for (const folder of folders) {
                const folderNode = getFolderNode(bundle, folder);
                if (
                    remainingTasks.find(
                        (task) => task.service.bundleId === bundle.id && task.location.parentId === folder.id,
                    )
                ) {
                    bundleNode.children.push(folderNode);
                    for (const location of folder.locations) {
                        const locationNode = getLocationNode(bundle, folder, location);
                        const matchingTasks = remainingTasks.filter(
                            (task) => task.service.bundleId === bundle.id && task.locationId === location.id,
                        );
                        if (matchingTasks.length > 0) {
                            folderNode.children.push(locationNode);
                            remainingTasks = remainingTasks.filter(
                                (task) => !matchingTasks.find((node) => node.id === task.id),
                            );
                            handleDirectories(
                                directories.filter((directory) => directory.bundleId === bundle.id),
                                bundle.id + '-' + folder.id + '-' + location.id,
                                locationNode.children,
                                matchingTasks,
                            );
                        }
                    }
                }
            }
        }
    }
    return result;
}

export default {
    buildTree,
};
